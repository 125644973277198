import VideoComponent from '@wix/thunderbolt-elements/src/components/VideoLegacy/viewer/VideoLegacy';
import VideoController from '@wix/thunderbolt-elements/src/components/VideoLegacy/viewer/VideoLegacy.controller';


const Video = {
  component: VideoComponent,
  controller: VideoController
};


export const components = {
  ['Video']: Video
};

